import { Fragment, useEffect, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useStore } from '@nanostores/react';
import { currentOrder, currentOrderItem, removeOrderItem } from '@/orderStore';
import type { Modifier, ModifierGroup } from '@packages/core';
import { Button } from '@/components';
import OrderItemForm from './OrderItemForm';

type Props = {
  modifierGroups: ModifierGroup[];
  modifiers: Modifier[];
};
const OrderItemModal = ({ modifierGroups, modifiers }: Props) => {
  const [itemModifiers, setItemModifier] = useState<Modifier[] | undefined>();
  const [itemModifierGroups, setItemModifierGroups] = useState<ModifierGroup[] | undefined>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const orderItemFormRef = useRef<HTMLFormElement>(null);

  const orderItem = useStore(currentOrderItem);
  const order = useStore(currentOrder);

  useEffect(() => {
    if (orderItem) {
      const itemModifierGroups = modifierGroups.filter(
        (mg) =>
          orderItem.menuItemModifierGroupUuids.includes(mg.uuid) || // one/extra mods
          orderItem.menuItemSizeModifierGroupUuid === mg.uuid, // size mods
      );
      const itemModifiers = modifiers.filter((m) =>
        itemModifierGroups.map((mg) => mg.uuid).includes(m.modifierGroupUuid),
      );
      setItemModifierGroups(itemModifierGroups);
      setItemModifier(itemModifiers);
    }
  }, [orderItem]);

  const closeModal = () => {
    reset();
  };

  const reset = () => {
    setItemModifier(undefined);
    setItemModifierGroups(undefined);
    currentOrderItem.set(undefined);
  };

  const handleAddToOrder = async () => {
    if (!orderItemFormRef.current) throw new Error('Form ref is not set');
    setIsSubmitting(true);
    const orderItemForm = orderItemFormRef.current;
    orderItemForm.requestSubmit();
    setIsSubmitting(false);
  };

  const handleRemoveOrderItem = (orderItemId: string) => {
    setIsSubmitting(true);
    removeOrderItem(orderItemId);
    reset();
    setIsSubmitting(false);
  };

  if (!orderItem) return null;

  return (
    <Transition.Root show={true} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative w-full transform rounded-lg bg-soft text-left shadow-xl transition-all sm:my-8 sm:max-w-xl">
                <div className="divide-y px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  {/* Menu item name & description */}
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="mb-3 text-lg font-medium leading-6 text-gray-900"
                      >
                        {orderItem.menuItemName}
                      </Dialog.Title>

                      {orderItem.menuItemDescription && (
                        <div className="mb-3">
                          <p className="text-sm text-gray-500">{orderItem.menuItemDescription}</p>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Form */}
                  {itemModifierGroups && itemModifiers && (
                    <OrderItemForm
                      modifiers={itemModifiers}
                      modifierGroups={itemModifierGroups}
                      orderItem={orderItem}
                      ref={orderItemFormRef}
                      afterValidSubmit={reset}
                    />
                  )}
                </div>

                {/* Action buttons */}
                <div className="space-y-2 px-4 py-3 sm:flex sm:space-y-0 sm:px-6">
                  <div className={'sm:flex-grow hidden sm:block'}>
                    {order?.orderItems.map((oi) => oi.uuid).includes(orderItem.uuid) &&
                      orderItem.uuid && (
                        <Button
                          onClick={() => handleRemoveOrderItem(orderItem.uuid)}
                          disabled={isSubmitting}
                          variant="outline"
                          className={'text-red-500 hover:text-red-400 hover:bg-red-100'}
                        >
                          Remove
                        </Button>
                      )}
                  </div>

                  <div
                    className={
                      'space-y-2 sm:flex sm:flex-row-reverse sm:space-y-0 sm:space-x-4 space-x-2'
                    }
                  >
                    <Button
                      onClick={handleAddToOrder}
                      disabled={isSubmitting}
                      className={'sm:ml-2'}
                    >
                      {order?.orderItems.map((oi) => oi.uuid).includes(orderItem.uuid)
                        ? 'Update'
                        : 'Add to order'}
                    </Button>

                    {order?.orderItems.map((oi) => oi.uuid).includes(orderItem.uuid) &&
                      orderItem.uuid && (
                        <Button
                          onClick={() => handleRemoveOrderItem(orderItem.uuid)}
                          disabled={isSubmitting}
                          variant="outline"
                          className={'text-red-500 hover:text-red-400 hover:bg-red-100 sm:hidden'}
                        >
                          Remove
                        </Button>
                      )}

                    <Button onClick={closeModal} variant={'outline'}>
                      Close
                    </Button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default OrderItemModal;
