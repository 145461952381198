type Props = {
  // All other props
  [x: string]: any;
};

const TextArea = (props: Props) => {
  return (
    <textarea
      rows={3}
      {...props}
      className={`block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-gray-500 focus:ring-gray-900 sm:text-sm ${props?.className}`}
    />
  );
};

export default TextArea;
